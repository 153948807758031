
import { Component, Mixins, Prop } from 'vue-property-decorator';
import { MyCoachMixin, VuetifyMixin, BAIconsMixin } from "@/mixins";
import { ScoutingReportModel } from '@/models/scoutingReport/ScoutingReportModel';
import { ScoutingReportScoreSelectorMixin } from "@/mixins/selectors/ScoutingReportScoreSelector";
import ScoreSelector from '@/components/forms/ScoreSelector.vue';
import ScoutingReportNotesBtn from "@/components/scoutingReport/ScoutingReportNotesBtn.vue";
import ScoutingMatrixElement from "@/components/scoutingReport/ScoutingMatrixElement.vue";

@Component({
	components: {
		ScoreSelector,
		ScoutingReportNotesBtn,
		ScoutingMatrixElement,
	},
})

export default class ScoutingReportMatrixV2 extends Mixins(MyCoachMixin, VuetifyMixin, ScoutingReportScoreSelectorMixin, BAIconsMixin){
	@Prop({ default: null, type: String }) report: ScoutingReportModel | null;

	showComments: boolean = true;
}
