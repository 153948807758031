
import { Component, Vue, Prop } from "vue-property-decorator";
import { TargetedSharingModel } from '@/models/targetedSharing/TargetedSharingModel';
import { RecruitingResponse } from '@/../types/enums/recruiting-response';

@Component
export default class SharingResponseBtn extends Vue {
    @Prop() type: RecruitingResponse;
    @Prop() share: TargetedSharingModel;
    @Prop() name: string;

    onClick() {
        this.$emit('response', { share: this.share, response: this.type });
    }

    get ToolTipColor(): string {
        return this.share.GetTypeColor(this.type, true, 'tooltip');
    }
    get ToolTip(): string {
        return this.share.GetTypeToolTip(this.type, this.name);
    }
    Color(hover: boolean): string {
        return this.share.GetTypeColor(this.type, hover);
    }
    Icon(hover: boolean): string {
        return this.share.GetTypeIcon(this.type, hover);
    }
}
