
import { VuetifyMixin, BAIconsMixin } from '@/mixins';
import { TeamModel } from '@/models/team';
import { Component, Mixins, Prop } from 'vue-property-decorator';
import { MobileNavItem } from '../ui/MobileNav.vue';

@Component
export default class TeamDashboardMobileNav extends Mixins(VuetifyMixin, BAIconsMixin){
	open: boolean = false;
	@Prop({ default: false, type: Boolean }) loading: boolean;
	@Prop({ required: true }) team: TeamModel;
	@Prop({ default: [] }) navItems: MobileNavItem[];

	get ActiveRouteText(): string{
		const route = this.navItems.find(item => item.route.name === this.$route.name);
		if(route === undefined) return "Team Dashboard";
		return route.text
	}
}
