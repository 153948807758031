
import { Component, Mixins, Prop, Watch } from 'vue-property-decorator';

import { TeamModel } from '../models/team';
import { teamDashboardStore } from '../store';
import Page from '../views/Page.vue';
import TeamDetailsCardV2 from '../components/teams/TeamDetailsCardV2.vue';
import PlayerManagement from '../components/teams/PlayerManagement.vue';
import { CurrentTeamMixin, LocalForageMixin, SportsSelectorMixin, VuetifyMixin } from '../mixins';
import { CalendarMode } from '../components/calendar/types/CalendarMode';
import { DepthChartModel } from '../models/depthChart';
import CalendarProvider from '@/components/hoc/CalendarProvider.vue';
import TeamDashboardInfoProvider from '@/components/hoc/TeamDashboardInfoProvider.vue';
import { CalendarEventModel } from '@/models/calendar/CalendarEventModel';
import * as Routes from '@/../types/constants/web_client_user.routes';
import { VCalendarDay } from '@/components/calendar/types/VCalendarDay';
import TeamCalendarSvg from '@/components/svg/TeamCalendarSvg.vue';
import RosterSvg from '@/components/svg/RosterSvg.vue';
import FormationsSvg from '@/components/svg/FormationsSvg.vue';
import GameWhistleSvg from '@/components/svg/GameWhistleSvg.vue';
import TeamDashboardMobileNav from '@/components/teams/TeamDashboardMobileNav.vue';
import { Route } from 'vue-router';
import { MobileNavItem } from '@/components/ui/MobileNav.vue';

@Component({
	components: {
		CalendarProvider,
		TeamDashboardInfoProvider,
		Page,
		PlayerManagement,
		TeamDetailsCardV2,
		TeamCalendarSvg,
		RosterSvg,
		FormationsSvg,
		GameWhistleSvg,
		TeamDashboardMobileNav,
	}
})
export default class TeamDashboardV2 extends Mixins(CurrentTeamMixin, LocalForageMixin, VuetifyMixin, SportsSelectorMixin){
	season: string = "2020";

	@Prop() teamId: string;
	@Prop({ default: false, type: Boolean }) athleteMode: boolean;

	get AthleteMode(): boolean{
		return this.athleteMode;
	}

	localForagePersistFields: Array<string | [string, any]> = [
		['calendarMode', CalendarMode.Month],
	];
	calendarMode: CalendarMode = CalendarMode.Month;
	updateCalendarMode(mode: CalendarMode): void{
		this.calendarMode = mode;
		this.persistField('calendarMode');
	}

	@Watch('teamId') updateTeamId(): void{
		this.initTeamDashboard();
	}

	private async initTeamDashboard(){
		await this.loadTeamFromUrl();
		this.chooseInitialRoute();
	}

	/**
	 * When loading the dashboard choose the first nav item as the initial route if a valid route isn't selected.  
	 * ie. Team Info when on desktop view
	 */
	chooseInitialRoute(): void{
		if(!this.DashboardNavItems.map(r => r.route.name).includes(this.$route.name)){
			const [ navItem ] = this.DashboardNavItems;
			this.$router.push(navItem.route);
		}
	}

	async created(): Promise<void> {
		await this.initTeamDashboard();
	}

	async loadTeamFromUrl(): Promise<void>{
		await teamDashboardStore.loadTeam(this.teamId);
	}

	get PageLoading(): boolean{
		return !teamDashboardStore.initialized || teamDashboardStore.loadingTeam;
	}
	get team(): TeamModel {
		return teamDashboardStore.team;
	}
	get depthCharts(): DepthChartModel[] {
		return teamDashboardStore.team.depthCharts;
	}
	expandCalendar(): void{
		this.$router.push({
			name: Routes.FullScreenCalendar,
			params:{
				teamId: this.teamId
			}
		});
	}
	collapseCalendar() {
		if (this.AthleteMode){
			this.$router.push({
				name: Routes.AthleteTeamDashboardCalendar,
				params: { teamId: this.teamId },
			})
		} else {
			this.$router.push({
				name: Routes.TeamDashboardCalendar,
				params: { teamId: this.teamId },
			})
		}
	}
	changeTeam(team: TeamModel): void{
		this.$router.push({
			name: 'TeamDashboard',
			params: { teamId: team.id },
		// eslint-disable-next-line @typescript-eslint/no-empty-function
		}).catch(() => {});
		this.loadTeamFromUrl();
	}

	addEvent({ focusDate }: { focusDate?: string }): void{
		if(this.AthleteMode) return;
		this.$router.push({
			name: Routes.TeamEventCreate,
			params:{
				...this.$route.params,
			},
			query:{
				focusDate,
			}
		});
	}

	eventClicked({calendarEvent, $event}: {calendarEvent: CalendarEventModel,  $event: VCalendarDay<CalendarEventModel>}): void{
		// Replace - with spaces to avoid it being converted to UTC date because we want the local date
		// Reference: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date/Date#timestamp_string
		let date = '';
		if ($event){
			date = new Date($event.day.date.replace(/-/g, "/")).toISOString()
		}else {
			date = calendarEvent.start.toISOString()
		}
		if(this.AthleteMode){
			this.$router.push({
				name: Routes.AthleteTeamEventView,
				params:{
					teamId: this.teamId,
					teamEventId: calendarEvent.id,
				},
				query: {
					focusDate: date
				}
			});
		}else{
			this.$router.push({
				name: Routes.TeamEventEdit,
				params:{
					teamId: this.teamId,
					teamEventId: calendarEvent.id,
				},
				query: {
					focusDate: date
				}
			});
		}
	}

	get DashboardNavItemClasses(): Record<string, boolean>{
		return {
			'dashboard-nav-item': true,
			'rounded-lg overflow-hidden mb-2': true,
		};
	}

	get DashboardNavItems(): MobileNavItem[]{
		const Roster = {
			iconComponent: 'RosterSvg',
			text: "Roster",
			route: this.RosterRoute,
			"v-icon": null,
		};
		const DepthCharts = {
			iconComponent: 'FormationsSvg',
			text: "Depth Charts",
			route: this.DepthChartRoute,
			"v-icon": null,
		};
		const Calendar = {
			iconComponent: 'TeamCalendarSvg',
			text: "Calendar",
			route: this.CalendarRoute,
			"v-icon": null,
		};
		const Games = {
			iconComponent: 'GameWhistleSvg',
			text: "Games",
			route: this.GameRoute,
			"v-icon": null,
		};
		const items: MobileNavItem[] = [ Roster ];
		if( !this.athleteMode ) {
			if( this.IsSupportedDepthCharts(this.team.Sport) ) items.push(DepthCharts);
		}
		items.push(Calendar);
		items.push(Games);
		if(this.IsMobile){
			items.unshift(this.MobileTeamInfoNavItem);
		}
		return items;
	}

	get MobileTeamInfoNavItem(): MobileNavItem{
		return {
			route: {
				name: this.AthleteMode ? Routes.AthleteTeamDashboard : Routes.TeamDashboard,
				params:{
					...this.$route.params,
				}
			},
			text: "Team Info",
			"v-icon": null,
			iconComponent: null,
		};
	}

	get RouterViewProps(): Record<string, any>{
		if(this.$route.name === Routes.TeamDashboardRoster || this.$route.name === Routes.AthleteTeamDashboardRoster){
			return {
				props:{
					team: this.team,
					readOnly: this.AthleteMode,
				}
			}
		}
		if(this.$route.name === Routes.TeamDashboardDepthCharts){
			return {
				props:{
					team: this.team,
				}
			}
		}
		if(this.$route.name === Routes.TeamDashboardCalendar || this.$route.name === Routes.AthleteTeamDashboardCalendar){
			return {
				props:{
					team: this.team,
				},
				on:{
					"update:view": this.updateCalendarMode.bind(this),
					"click:add-event": this.addEvent.bind(this),
					"click:event": this.eventClicked.bind(this),
					"expand:Calendar": this.expandCalendar.bind(this),
				}
			}
		}
		if(this.$route.name === Routes.TeamDashboardGames || this.$route.name === Routes.AthleteTeamDashboardGames){
			return {
				props:{
					teamId: this.teamId,
				},
				on:{
					"click:event": this.eventClicked.bind(this),
				}
			}
		}
		if( this.$route.name === Routes.FullScreenCalendar ) {
			return {
				props: {
					teamId: this.teamId,
				},
				on: {
					"collapse:Calendar": this.collapseCalendar.bind(this),
				}
			}
		}
		return {};
	}

	/**
	 * On mobile, hide the Team Details card unless we're on the root Team Dashboard page. This nav iteam is available on mobile
	 */
	get ShowTeamDetailsCard(): boolean{
		return !this.IsMobile || this.$route.name === Routes.TeamDashboard || this.$route.name === Routes.AthleteTeamDashboard;
	}

	get CalendarRoute(): Partial<Route>{
		return {
			name: this.athleteMode ? Routes.AthleteTeamDashboardCalendar : Routes.TeamDashboardCalendar,
			params:{
				...this.$route.params,
			}
		}
	}

	get RosterRoute(): Partial<Route>{
		return {
			name: this.athleteMode ? Routes.AthleteTeamDashboardRoster : Routes.TeamDashboardRoster,
		};
	}
	get DepthChartRoute(): Partial<Route>{
		return {
			name: Routes.TeamDashboardDepthCharts,
		};
	}
	get GameRoute(): Partial<Route>{
		return {
			name: this.athleteMode ? Routes.AthleteTeamDashboardGames : Routes.TeamDashboardGames,
		};
	}

}
