
import { Component, Mixins, Prop } from 'vue-property-decorator';
import { CSVUploadMixin } from "@/mixins/CSVUploadMixin";
import CSVUploadDialog from '@/components/teams/CSVUploadDialog.vue';
import { CalendarEventModel } from '@/models/calendar/CalendarEventModel';
import { TeamEventType } from '@/../types/enums';
import { getTime, formatDateEval } from '@/helpers/date';
import { BAIconsMixin, StringsMixin } from '@/mixins';
import { DataTableHeader } from 'vuetify';

@Component({
	components: {
		CSVUploadDialog,
	}
})
export default class CalendarImportDialog extends Mixins(CSVUploadMixin, StringsMixin, BAIconsMixin) {
    @Prop({ type: String, required: true }) calendarId: string;

    showUploadDialog = false;

    today: Date = new Date();
	sampleCSVData = [{
		eventType: TeamEventType.Game,
		name: "Our Team vs Their Team",
		date: formatDateEval(this.today),
        time: getTime(this.today),
		description: "League Cup", 
		venue: "Home Park",
		address: "1 Stadium Dr", 
	}];

	parseSchedule(csv: string): CalendarEventModel[] {
        this.$emit('parse:start');
		const jsonEntries = this.csv2json(csv);
		const events: CalendarEventModel[] = jsonEntries.map( entry => {
            var entryType: TeamEventType = TeamEventType.Game;
            if( this.IsNotEmpty(entry.eventType) ) {
                switch( entry.eventType ) {
                    case "Game": 
                        entryType = TeamEventType.Game;
                        break;
                    case "Practice": 
                        entryType = TeamEventType.Practice;
                        break;
                    case "Social": 
                        entryType = TeamEventType.Social;
                        break;
                }
            }
            const datetime = `${entry.date} ${entry.time}`;
			const event: CalendarEventModel = new CalendarEventModel();
            event.name = entry.name;
            event.parentId = this.calendarId;
            event.eventType = entryType;
            event.start = new Date(datetime);
            event.end = new Date(event.start.getTime() + 2 * 60 * 60 * 1000);
            event.venue = entry.venue;
            event.venueNotes = entry.description;
            event.address = entry.address;
            event.participants = [];
            event.recurrenceRule = null;

			return event;
		})

        this.$emit('parse:complete');
		return events;
	}

    unconfirmedEvents: Array<CalendarEventModel> = [];
    showConfirmationDialog: boolean = false;
	async onEventsLoaded(events: CalendarEventModel[]) {
        this.unconfirmedEvents = events;
        this.showConfirmationDialog = true;
    }
    get ConfirmationHeaders(): DataTableHeader[] {
        return [
            {text: 'Name', value: 'name', sortable: false},
            {text: 'Date', value: 'start', sortable: false},
            {text: 'Location', value: 'venue', sortable: false},
        ]
    }

    onCancel() {
        this.showConfirmationDialog = false;
    }
    onOK() {
        this.$emit('update:data', this.unconfirmedEvents);
    }
}
