
import { Component, Mixins, Prop } from "vue-property-decorator";
import { CoachObservationsMixin, BAIconsMixin } from "@/mixins";
import { TargetedSharingModel } from '@/models/targetedSharing/TargetedSharingModel';

@Component
export default class SharingResponseNote extends Mixins(CoachObservationsMixin, BAIconsMixin) {
    @Prop() share: TargetedSharingModel;
    @Prop() name: string;

    showNotes: boolean = false;
    showGameObservations: boolean = false;
    get ObservationsAthleteId(): string {
        return this.share.parentId;
    }
    onClick() {
        this.showNotes = true;
    }
    onCancel() {
        this.showNotes = false;
    }
    onOK() {
        this.showNotes = false;
        this.$emit('update:notes', { share: this.share, notes: this.share.coachNotes });
    }

    get HasNote(): boolean {
        return this.IsNotEmpty(this.share.coachNotes);
    }
    get ToolTip(): string {
        return this.HasNote? 'Edit Notes' : 'Add Notes';
    }
    get Icon(): string {
        return this.HasNote? this.BAIcons.editNote : this.BAIcons.observationAdd;
    }
}
