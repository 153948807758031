
import { Component, Prop, Mixins, Watch } from "vue-property-decorator";
import AttachmentsArea from "@/components/forms/AttachmentsArea.vue";
import Discussion from '@/components/courses/Discussion.vue';
import BoxScore from '@/components/teams/BoxScore.vue';
import { CalendarEventModel } from '@/models/calendar/CalendarEventModel';
import LineupCard from '@/components/teams/LineupCard.vue';
import { TeamModel } from '@/models/team';
import { LineupModel } from '@/models/lineup';
import PlayerManagement from "@/components/teams/PlayerManagement.vue";
import { CalendarEventParticipant, GameOccurence } from '@/../types/interfaces';
import TeamAttendance from '@/components/teams/TeamAttendance.vue';
import * as Routes from "@/../types/constants/web_client_user.routes";
import { EventProgress, SoccerTimelinePeriodValues, SoccerGameOccurenceTypeValues, SoccerGameTimingValues, SoccerGameTiming } from '@/../types/enums';
import { CalendarEventsApi } from "@/api/CalendarApi";
import SoccerGameTimeline from "@/components/teams/SoccerGameTimeline.vue";
import { TeamEventType } from '@/../types/enums';
import ContextMenu from '@/components/ui/ContextMenu.vue';
import { formatDatePretty,getTime,eventProgress, formatDateHyphensYYYYMMDD } from '@/helpers/date';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import viewMapPic from "@/assets/img/view-map-icon.png";
import { AuthMixin, CurrentTeamMixin, VuetifyMixin, BAIconsMixin } from '@/mixins';
import { Tag } from '@/models/tag/Tag';
import { getEventColor } from '@/helpers/default-event-types';
import { VideoClipModel } from '@/models/video/VideoClipModel';
import PlayerTagger from "@/components/teams/PlayerTagger.vue";
import BAVideoStudio from '@/components/videoStudio/BAVideoStudio.vue';
import VideoClipLibrary from '@/components/ui/videos/VideoClipLibrary.vue';
import VideoLibraryProvider from '@/components/hoc/VideoLibraryProvider.vue';
import { GameResultApi } from '@/api/GameResultApi';
import { GameResultModel } from '@/models/calendar/GameResultModel';
@Component({
	components: { BoxScore, Discussion,LineupCard,PlayerManagement,TeamAttendance,SoccerGameTimeline, AttachmentsArea, ContextMenu, PlayerTagger, BAVideoStudio, VideoClipLibrary, VideoLibraryProvider, },
})

export default class TeamEvent extends Mixins(AuthMixin, CurrentTeamMixin, VuetifyMixin, BAIconsMixin) {
	get teamEventId(): string | null{
		if(!this.event) return null;
		return this.event.id;
	}
	get teamId(): string{
		if(!this.team) return "";
		return this.team.id;
	}
	@Prop({ default: false }) athleteMode: boolean;
	@Prop({ default: null }) event: CalendarEventModel | null = null;
	@Prop({ default: null }) gameResult: GameResultModel | null = null;
	@Prop({ default: null }) focusDate: string;

	get GameResult(): GameResultModel{
		if(this.gameResult === null) return new GameResultModel();
		return this.gameResult;
	}

	get FocusDate(): Date {
		return new Date(this.focusDate ? this.focusDate : undefined);
	}

	@Prop({ default: null }) team: TeamModel | null = null;
	@Prop({ default: null }) calendarId: string | null = null;
	@Prop({ default: null }) calendarEventsApi: CalendarEventsApi | null = null;
	@Prop({ default: null }) gameResultApi: GameResultApi | null = null;
	deleteEventLoading: boolean = false;
	showDeleteDialog: boolean = false;
	showFinishDialog: boolean = false;
	showFinishLoading: boolean = false;
	videoStudioVisible: boolean = false;

	@Watch('GameResult')
	setupFormValue(gameResult: GameResultModel): void{
		if(gameResult === null) return;
		this.formValue.homeScore = gameResult.homeScore;
		this.formValue.opponentScore = gameResult.opponentScore;
		this.formValue.gameTiming = gameResult.gameTiming;
		this.formValue.showTimeline = gameResult.showTimeline;
	}

	formValue: {
		homeScore: number,
		opponentScore: number,
		gameTiming: SoccerGameTiming,
		showTimeline: boolean,
	} = {
		homeScore: null,
		opponentScore: null,
		gameTiming: null,
		showTimeline: false,
	};
	viewMapPic = viewMapPic;
	SoccerGameOccurenceTypeValues = SoccerGameOccurenceTypeValues;
	SoccerTimelinePeriodValues = SoccerTimelinePeriodValues;
	SoccerGameTimingValues = SoccerGameTimingValues;

	get VideoParentId(): string{
		return this.teamId;
	}

	get EventReady(): boolean{
		return this.event !== null;
	}

	get ShowEventVideoClips(): boolean{
		return this.teamEventId !== undefined;
	}

	get EventVideoTagFilter(): Tag[]{
		return [
			new Tag(this.event.EventName, this.event.RootId, this.getColor(getEventColor(this.event.eventType))),
		];
	}

	filterPlayers: Tag[] = [];
	playerClipFilter(clip: VideoClipModel): boolean{
		return clip.hasTag(this.filterPlayers);
	}

	get AttendanceProgress(): boolean{
		if (this.gameResult !== null){
			return this.GameResult.attendanceFinished;
		}
		return false
	}

	get Lineup(): LineupModel{
		if (this.gameResult === null){
			return null
		}
		return this.GameResult.lineup
	}

	get EventProgress(): EventProgress{
		if (this.event === null){
			return null
		}
		return eventProgress(this.event.start, this.event.end, this.FocusDate)
	}

	get MapLink(): string{
		if (this.event.address){
			const address = this.event.address
			return encodeURI('https://www.google.com/maps/search/?api=1&query='+address)
		} else if (this.event.venue){
			const venue = this.event.venue
			return encodeURI('https://www.google.com/maps/search/?api=1&query='+venue)
		}
		return 'https://www.google.com/maps'
	}

	get FileUploadApiPrefix(): string {
		return `team/${this.teamId}/calendar/${this.calendarId}/calendarEvent/${this.DocumentUploadId}/files`;
	}

	get EventRootId(): string | null{
		if(this.event){
			return this.event.RootId;
		}
		return null;
	}

	get DocumentUploadId(): string | null{
		const dateSlug = formatDateHyphensYYYYMMDD(this.FocusDate)
		return `${this.EventRootId}_${dateSlug}`;
	}

	get DiscussionId(): string{
		const dateSlug = formatDateHyphensYYYYMMDD(this.FocusDate);
		return `${this.EventRootId}_event_${dateSlug}`;
	}

	defaultGameOccurance = (): GameOccurence => ({
		team: "",
		type: null,
		eventTime: null,
		player: "",
		subPlayerIn: "",
		subPlayerOut: "",
		period: null,
	});
	get Loading(): boolean {
		return !this.CurrentTeamReady;
	}

	get IsGame(): boolean{
		if (this.event === null){
			return false
		}
		return this.event.eventType === TeamEventType.Game;
	}

	get IsPractice(): boolean{
		if (this.event === null){
			return false
		}
		return this.event.eventType === TeamEventType.Practice
	}

	get IsSocial(): boolean{
		if (this.event === null){
			return false
		}
		return this.event.eventType === TeamEventType.Social
	}

	get AthleteMode(): boolean {
		return this.athleteMode;
	}

	get EventName(): string {
		if (this.event === null){
			return ''
		}
		return this.event.name;
	}

	get EventInfoDate(): string | null {
		if (this.event === null){
			return null
		}
		const date = this.event.recurrenceRule === null ? this.event.start : this.FocusDate;

		return formatDatePretty(date);
	}

	get EventInfoTime(): string | null {
		if (this.event === null){
			return null
		}
		return getTime(this.event.start);
	}
	get PlayerParticipants(): CalendarEventParticipant[]{
		if(this.event === null) return [];
		return this.event.participants.filter(p => !p.coachId);
	}


	get FormHasLineup(): boolean {
		if (this.event === null){
			return false
		}
		return this.GameResult.lineup !== null;
	}

	get FormColumns(): {
		left: Record<string, string>;
		right: Record<string, string>;
		} {
		return {
			left: {
				cols: "12",
				md: "6",
				sm: "12",
			},
			right: {
				cols: "12",
				md: "6",
				sm: '12'
			}
		};
	}

	goBack(): void {
		if (this.$route.query.largeCalendar !== undefined) {
			this.$router.push({
				name: Routes.FullScreenCalendar,
				params:{
					teamId: this.teamId
				}
			});
		}else if (this.AthleteMode) {
			this.$router.push({
				name: Routes.AthleteTeamDashboard
			});
		} else {
			this.$router.push({
				name: Routes.TeamDashboard,
				params:{
					...this.$route.params,
				},
				query:{
					...this.$route.query,
				},
			});
		}
	}

	async isFinished(isFinished: boolean): Promise<void>{
		this.GameResult.attendanceFinished = isFinished;
		await this.patchEvent(this.event);
		this.$emit('update:attendance-finished');
	}

	deleteOccurence(index: number): void{
		this.GameResult.gameOccurence.splice(index,1);
		this.updateGameResult();
	}

	addOccurence(): void{
		this.GameResult.gameOccurence.push(this.defaultGameOccurance())
		this.updateGameResult();
	}

	errorMessage: string | null = null;

	async confirmFinish(): Promise<void> {
		this.formValue.showTimeline = true;
		this.updateGameResult();
		this.showFinishDialog = false;
	}

	updateGameResult(): void{
		if(this.gameResult === null){
			this.gameResult = new GameResultModel();
		}
		this.gameResult.homeScore = this.formValue.homeScore;
		this.gameResult.opponentScore = this.formValue.opponentScore;
		this.gameResult.gameTiming = this.formValue.gameTiming;
		this.gameResult.showTimeline = this.formValue.showTimeline;
		this.$emit('update:game-result', this.gameResult);
	}

	async patchEvent(event: CalendarEventModel): Promise<CalendarEventModel>{
		this.updateGameResult();
		return await this.calendarEventsApi.patch(event);
	}

	async submit(validate: () => Promise<boolean>): Promise<void>{
		this.errorMessage = null;
		const isValid = await validate();
		if(!isValid){
			this.errorMessage = 'Please ensure all required fields are provided.';
			this.$vuetify.goTo(0);
		}else{
			this.showFinishDialog = true;
		}
	}


	async deleteEvent(): Promise<CalendarEventModel | null> {
		try {
			const teamEvent = new CalendarEventModel();
			teamEvent.id = this.teamEventId;
			this.deleteEventLoading = true;
			await this.calendarEventsApi.deleteByRootId(teamEvent.rootId);
			this.deleteEventLoading = false;
			this.goBack();
			return teamEvent;
		} catch (e) {
			this.deleteEventLoading = false;
			console.log("Failed to delete event", e);
			return null;
		}
	}

	editEvent():void{
		this.$router.push({
			name: Routes.TeamEventEdit,
			params:{
				teamEventId: this.teamEventId,
				teamId: this.teamId,
			},
			query: {
				focusDate: this.FocusDate.toISOString(),
			}
		});
	}
}
