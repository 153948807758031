
import { Component, Vue } from 'vue-property-decorator';
import TeamDashboardV2 from './TeamDashboardV2.vue';
import { VueConstructor } from 'vue';

@Component({
	components: {
		TeamDashboardV2,
	}
})
/**
 * Root view for the Athlete or Coach's TeamDashboard. Chooses which component to be rendered
 */
export default class TeamDashboardView extends Vue{
	get TeamDashboardComponent(): VueConstructor{
		return TeamDashboardV2;
	}
}
