
import { Component, Mixins, Prop, PropSync } from "vue-property-decorator";
import { VuetifyMixin, BAIconsMixin, CurrentTeamMixin, StringsMixin } from "@/mixins";
import { teamCreateStore, teamsStore } from '@/store';
import { TeamModel } from '@/models/team';
import { TeamCreateOptions } from '@/../types/interfaces';


@Component
export default class CalendarAddLinkDialog extends Mixins(VuetifyMixin, BAIconsMixin, CurrentTeamMixin, StringsMixin) {
	@PropSync('show', { default: false, type: Boolean }) Show: boolean;
	newCalendarURL: string = "myTeam";

	mounted(){
		this.newCalendarURL= this.initURL();
	}

	initURL(): string{
		if(this.CurrentTeamReady)
			return (this.IsEmpty(this.CurrentTeam.teamCalendarURL)? null: this.CurrentTeam.teamCalendarURL);
		else 
			return null;
	}

	hasLink(): boolean{
		return this.IsNotEmpty(this.CurrentTeam.teamCalendarURL);
	}

	cancelEdit(): void{
		this.newCalendarURL= this.CurrentTeam.teamCalendarURL;
		this.Show=false;
	}

	async submit(): Promise<void>{
		if(this.CurrentTeamReady){
			const opts: TeamCreateOptions = {
				invitePlayers: false,
				inviteStaff: false,
				sourceCoachId: null,
			};
			this.CurrentTeam.teamCalendarURL= this.newCalendarURL;
			let team: TeamModel;
			team = teamsStore.myTeams.find(t => t.id === this.CurrentTeamId);
			team.teamCalendarURL= this.CurrentTeam.teamCalendarURL;
			team.id = this.CurrentTeamId;
			const newTeam = await teamCreateStore.patchTeam({team, opts});
			teamsStore.spliceTeam({ team: newTeam});
			this.Show=false;
		}
	}
}
