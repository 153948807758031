
import { Component, Mixins, Prop } from 'vue-property-decorator';
import ScoreSelector from '@/components/forms/ScoreSelector.vue';
import { ScoutingReportScoreSelectorMixin } from "@/mixins/selectors/ScoutingReportScoreSelector";

@Component({
	components: {
		ScoreSelector,
	},
})

export default class ScoutingReportMatrixElement extends Mixins(ScoutingReportScoreSelectorMixin){
	@Prop({ required: true }) public heading: string;
	@Prop({}) public notes: string;
	@Prop({}) public score: number;
	@Prop({}) public showComments: boolean;
}
