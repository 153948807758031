import { render, staticRenderFns } from "./SharingResponseBtn.vue?vue&type=template&id=09568aa8&scoped=true&"
import script from "./SharingResponseBtn.vue?vue&type=script&lang=ts&"
export * from "./SharingResponseBtn.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "09568aa8",
  null
  
)

export default component.exports