import { render, staticRenderFns } from "./SharingResponseNote.vue?vue&type=template&id=48e9f61e&scoped=true&"
import script from "./SharingResponseNote.vue?vue&type=script&lang=ts&"
export * from "./SharingResponseNote.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "48e9f61e",
  null
  
)

export default component.exports